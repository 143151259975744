<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
    <div>
      <vx-card title="Customer Deposit Settlement Detail">
        <div class="vx-row mt-3">
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <!-- <span class="py-2 h5 font-semibold">Customer Deposit Detail</span><hr> -->
            <div class="vx-row my-6">
              <div class="vx-col sm:w-1/3 w-full">
                  <span>CN Supplier ID</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full input-readonly" v-model="detail.code" readonly />
              </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Reference</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.reference" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Supplier</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.supplier" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Document Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.docDate" readonly />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Posting Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.postingDate" readonly />
                </div>
            </div>
            <!-- <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input class="w-full input-readonly" v-model="detail.depositNumber" readonly />
                </div>
            </div> -->
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Note</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-input class="w-full input-readonly" v-model="detail.postingDate" readonly /> -->
                    <vs-textarea class="w-full bg-dark" v-model="detail.note" disabled />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Attachment</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <!-- <vs-input class="w-full input-readonly" v-model="detail.depositDate" readonly /> -->
                    <div v-if="fileAttactment.files.length > 0">
                      <vs-divider> List Attachments </vs-divider>
                      <template v-for="(file, i) in fileAttactment.files">
                        <div class="vx-row my-2" :key="i" v-if="file.flag != 'remove'">
                          <div class="vx-col w-full">
                            <div class="flex gap-2">
                              {{ i + 1 }}.
                              <a href="javascript:void(0);" v-on:click="handleShowAttachment(i)">{{
                                file.name
                              }}</a>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                </div>
            </div>
            <!-- <span class="py-2 h5 font-semibold">Allocate</span><hr>
            <div class="vx-row mb-6">
              <vs-table
                :data="allocate"
                style="width: 100%; margin-left: 2%"
              >
                <template slot="thead">
                  <vs-th></vs-th>
                  <vs-th> Reference </vs-th>
                  <vs-th> Value </vs-th>
                  <vs-th></vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            <vs-button
                                size="small"
                                type="line"
                                @click="removeField(indextr)"
                                icon-pack="feather"
                                icon="icon-trash"
                            ></vs-button>
                        </vs-td>
                        <vs-td>
                            <vs-input class="w-full" v-model="tr.reference"/>
                        </vs-td>
                        <vs-td>
                            <vue-number
                                :customClass="`w-full`"
                                v-model="tr.value"
                                :masked="true"
                            ></vue-number>
                        </vs-td>
                        <vs-td>
                            <vs-button
                                v-if="indextr == 0"
                                style="margin-right: 5px"
                                size="small"
                                @click="addField(indextr)"
                                icon-pack="feather"
                                icon="icon-plus"
                            ></vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div> -->
          </div>
          <!-- <div class="vx-col sm:w-1/2 w-full mb-base">
            <span class="py-2 h5 font-semibold">Payment Detail</span><hr>
            <div class="vx-row my-6" style="width: 100%">
              <vs-table
                stripe
                :data="dataGet.cashBankLine"
                style="width: 100%; margin-left: 2%"
              >
                <template slot="thead">
                  <vs-th> Payment Code </vs-th>
                  <vs-th> Type </vs-th>
                  <vs-th> Payment Value </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>{{ tr.PaymentCode }}</vs-td>
                        <vs-td>{{ tr.TransactionType }}</vs-td>
                        <vs-td>{{ formatPrice(tr.PaymentValue) }}</vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div>
          </div> -->
        </div>
        <div class="vx-row mt-3">
          <div class="vx-col sm:w-1/1 w-full mb-base">
            <span class="py-2 h5 font-semibold">Credit Note Supplier Line</span><hr>
            <div class="vx-row my-6" style="width: 100%">
              <vs-table
                stripe
                :data="line"
                style="width: 100%; margin-left: 2%"
              >
                <template slot="thead">
                  <vs-th> Action </vs-th>
                  <vs-th> Customer </vs-th>
                  <vs-th> Description </vs-th>
                  <vs-th> CN Supplier Value </vs-th>
                  <vs-th> Customer Deposit Data </vs-th>
                  <vs-th> Settled Value </vs-th>
                  <vs-th> Remaining Value </vs-th>
                </template>

                <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <vx-tooltip text="Edit" class="flex justify-center">
                            <vs-button
                              type="line"
                              icon-pack="feather"
                              icon="icon-eye"
                              v-on:click.stop="view(tr)"
                            />
                          </vx-tooltip>
                        </vs-td>
                        <vs-td v-if="tr.customer_code">({{ tr.customer_code }}) {{ tr.customer_name }}</vs-td>
                        <vs-td v-else>-</vs-td>
                        <vs-td>{{ tr.description }}</vs-td>
                        <vs-td>{{ tr.value_credit==0?formatPrice(tr.value_debit):formatPrice(tr.value_credit) }}</vs-td>
                        <vs-td v-if="tr.deposit_code">
                          Deposit Code : {{ tr.deposit_code }}<br/>
                          Deposit Date : {{ formatDate(tr.date_payment) }}<br/>
                          Posting Date : {{ formatDate(tr.date) }}
                        </vs-td>
                        <vs-td v-else></vs-td>
                        <vs-td>{{ formatPrice(tr.deposit_used) }}</vs-td>
                        <vs-td>{{ formatPrice((tr.value_debit+tr.value_credit)-tr.value_settled) }}</vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>
        </div>
        <div class="vx-row mt-3">
          <div class="vx-col sm:w-1/2 w-full mb-base">
            <!-- <vs-button
                class="mb-2 mr-2"
                v-on:click="handleSubmit()"
            >
                Save
            </vs-button> -->
            <vs-button
                class="mb-2 mr-2"
                color="danger"
                type="border"
                v-on:click="navigateBack()"
              >
                Close
              </vs-button>
          </div>
        </div>
      </vx-card>
      <vs-popup title="Preview" :active.sync="fileShow">
        <div class="vx-row m-2 mt-6">
          <div class="vx-col w-full">
            <img
              width="100%"
              :src="fileAttactment.preview ? fileAttactment.preview.src : ''"
              :alt="fileAttactment.preview ? fileAttactment.preview.name : ''"
              srcset=""
            />
          </div>
        </div>
      </vs-popup>
    </div>
  </template>
  <script>
    import vSelect from "vue-select";
    import Datepicker from "vuejs-datepicker";
    import moment from "moment";
    import VueNumber from "@/components/vue-number/component.vue";
  
    export default {
      components: {
        Datepicker,
        "v-select": vSelect,
        "vue-number":VueNumber,
      },
      props: {
        selected: Object,
      },
      data() {
        return {
          viewOnly: false,
          line: [],
          dataGet: {},
          customerDeposit: {},
          optionMethod: ["Cash", "Giro", "Cheque", "Transfer"],
          detail: {
              reference: "",
              code: "",
              supplier: "",
              docDate: "",
              postingDate: "",
              note: "",
          },
          allocate: [{
              id: 0,
              reference: "",
              value: 0,
          }],
          fileAttactment: {
            multiple: true,
            disabled: false,
            value: null,
            preview: null,
            accepted_types: ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx"],
            files: [],
          },
        }
      },
      computed: {
        // totalUsed() {
        //     let used = this.dataGet.cashBankLine
        //         .map((v) => parseFloat(v.PaymentValue))
        //         .reduce((acc, curr) => acc + curr, 0);
        //     return this.formatPrice(used);
        // },
        // totalAvailable() {
        //     let used = this.totalUsed.toString().replace(/,/g, "")
        //     let depoVal = this.customerDeposit.DepositValue
        //     let allocate = this.totalAllocate.toString().replace(/,/g, "")

        //     let available = parseFloat(depoVal) - parseFloat(used) - parseFloat(allocate)

        //     return this.formatPrice(available);
        // },
        // totalAllocate() {
        //     let allocate = this.allocate
        //         .map((v) => parseFloat(v.value.toString().replace(/,/g, "")))
        //         .reduce((acc, curr) => acc + curr, 0);
        //     return this.formatPrice(allocate);
        // },
      },
      async mounted() {
        console.log(this.selected, "selected data");
        const path = this.$router.currentRoute.path.toLowerCase();
        const id = parseInt(atob(this.$route.params.id));
        if (path.includes("view")) {
          this.viewOnly = true;
          console.log(path.includes("view"));
        }

        await this.getCustomerDeposit(id)
        this.getGeneralForm()

      },
      methods: {
        navigateBack() {
            return this.$router.push({ name: "customer-deposit-settlement" });
        },
        view(selected) {
          if (!this.viewOnly) {
            return this.$router.push({
              name: "customer-deposit-settlement.form",
              params: { selected: selected, id: btoa(selected.id) },
            });
          } else {
            return this.$router.push({
              name: "customer-deposit-settlement.form.view",
              params: { selected: selected, id: btoa(selected.id) },
            });
          }
        },
        async getCustomerDeposit(id) {
            try {
                this.$vs.loading();
                const resp = await this.$http.get(`/api/v2/finance/credit-note-supplier/detail/${id}`);
                console.log(resp);
                this.dataGet = resp.data.cnSupplier;
                this.line = resp.data.line;
                // this.customerDeposit = resp.data.cashBank;

                this.$vs.loading.close();
            } catch (e) {
                console.log(e);
                this.$vs.loading.close();
            }
        },
        getGeneralForm() {
            this.detail.code = this.dataGet.code
            this.detail.reference = this.dataGet.reference
            this.detail.supplier = "("+this.dataGet.supplier_code+") "+this.dataGet.supplier_name
            this.detail.note = this.dataGet.note
            this.detail.postingDate = this.formatDate(this.dataGet.posting_date)
            this.detail.docDate = this.formatDate(this.dataGet.date)

            if (this.dataGet.credit_note_supplier_attachment) {
              this.dataGet.credit_note_supplier_attachment.forEach((el) => {
                this.fileAttactment.files.push({
                  id: el.ID,
                  flag: "",
                  name: el.file_name,
                  file_path: el.path,
                });
              });
            }
            // this.detail.depositValue = this.formatPrice(this.dataGet.DepositValue)

            // this.allocate = []
            // this.dataGet.cashBankLine.forEach(line => {
            //     if (line.TransactionType == "allocation") {
            //         let dataAllocate = {
            //             id: line.ID,
            //             reference: line.Info.reference,
            //             value: this.formatPrice(line.PaymentValue),
            //         }

            //         this.allocate.push(dataAllocate)
            //     }
            // });

            // if (this.allocate.length == 0) {
            //     this.allocate = [{
            //     id: 0,
            //     reference: "",
            //     value: 0,
            // }]
            // }
        },
        handleShowAttachment(i) {
          if (this.fileAttactment.files[i]) {
            let src = "";
            console.log("this.fileAttactment.files[i]", this.fileAttactment.files[i]);
            if (this.fileAttactment.files[i] && this.fileAttactment.files[i].file_path) {
              this.$vs.loading();
              const file = this.getUrlFileAwsS3(this.fileAttactment.files[i].file_path);
              file
                .then((resp) => {
                  src = resp.url;
                  if (
                    this.fileAttactment.files[i].name.search(".jpg") >= 0 ||
                    this.fileAttactment.files[i].name.search(".jpeg") >= 0 ||
                    this.fileAttactment.files[i].name.search(".png") >= 0
                  ) {
                    this.fileShow = true;
                    this.fileAttactment.preview = this.fileAttactment.files[i];
                    this.fileAttactment.preview.src = src;
                  } else {
                    window.open(src, "_blank");
                  }
                  this.$vs.loading.close();
                })
                .catch((e) => {
                  console.log("get url aws error=>", e);
                  this.$vs.loading.close();
                });
            } else {
              src = URL.createObjectURL(this.fileAttactment.files[i]);
              if (this.fileAttactment.files[i].type.search("image") >= 0) {
                this.fileShow = true;
                this.fileAttactment.preview = this.fileAttactment.files[i];
                this.fileAttactment.preview.src = src;
              } else {
                window.open(src, "_blank");
              }
            }
          }
        },
        handleSubmit() {
            if (parseFloat(this.totalAvailable.toString().replace(/,/g, "")) < 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Available can't be less than 0",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return
            }
            
            this.$vs.loading();
            
            this.allocate.forEach(v => {
                v.value = parseFloat(v.value.toString().replace(/,/g, ""))
            });

            let param = {
                allocate: this.allocate,
                cashBankID: this.customerDeposit.ID
            }

            this.$http
                .post("/api/v1/cash-bank/customer-deposit-allocate", param)
                .then((resp) => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                    title: "Success",
                    text: "",
                    color: "success",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                    return this.$router.push({ name: "customer-deposit" });
                } else {
                    this.$vs.notify({
                    title: "Error",
                    text: "Failed to create",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                    });
                }
                })
                .catch((e) => {
                this.$vs.notify({
                    title: "Error",
                    text: "Failed to create",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                });
        },
        addField() {
            this.allocate.push({
                id: 0,
                reference: "",
                value: 0,
            });
        },
        removeField(index) {
            // if (this.allocate.length > 0) {
            //     this.$vs.notify({
            //         title: "Error",
            //         text: "Cannot remove",
            //         color: "danger",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-check",
            //     });
            // }

            this.allocate.splice(index, 1);
            console.log(index, "removeFieldCustDepo");
        },
        formatPrice(angka, prefix = "") {
            let number = parseFloat(angka).toFixed(2);
            return number
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate(date) {
          return moment(date).format('YYYY-MM-DD');
        },
      }
    }
  </script>
<style scoped>
  .margin-btm {
    margin-bottom: 1rem !important;
  }
  
  .vs-input--input.normal {
    text-align: end;
  }
</style>